#footer {
    background-color: #359AD3;
    padding: 5vh 0;

    .center {
        display: flex;
        flex: 2;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .location {
            // width: 100%;

            .logo {
                margin-bottom: 5px;
            }

            p {
                margin: 0;
                padding: 0;
                color: #fff;
                font-size: 0.8em;
            }
        }

        .aboutus {
            // width: 100%;
            display: flex;
            flex-direction: column;

            a {
                color: #fff;
            }
        }

        .usefullink {
            // width: 100%;
            display: flex;
            flex-direction: column;

            a {
                color: #fff;
            }
        }



    }
   
}
#slogan {
    background-color: #ffca0f;

    .center {
        text-align: center;
        font-weight: 700;
        display: flex;
        justify-content: space-between;
    }
}

@media only screen and (max-width: 600px) {
    #footer {
        .center {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
    #slogan {
    
        .center {
            display: block;
        }
    }
}



#slogan::before {
    background-color: red;
    height: 3px;
    width: 100vw;
    content: "";
    display: block;
}

#slogan::after {
    background-color: red;
    height: 3px;
    width: 100vw;
    content: "";
    display: block;
}