.downloadcontainer{
    min-height: 80vh;
    .title{
        
        font-size: 1.1em;
        font-weight: 900;
        text-align: center;
        margin: 0;
        padding: 0;
        height: 10vh;
        display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-bottom: 3vh;
          background-color: #64c1f5;

          h3 {
            color: #ffffff;
            margin: 10px 0px;

        }
      
    }

       .downloadlist {
        margin: 10px 0px;
        display: block;
        width: 100%;
    }
       
}