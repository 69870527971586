.adminlogin{
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    .group {
        position: relative;
      }
      
      .form {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        border: 1px solid white;
        padding: 120px 40px;
        padding-top: 60px;
        padding-bottom: 90px;
        padding-right: 40px;
        padding-left: 40px;
        background-color: #2c2c2c;
        border-radius: 20px;
        position: relative;
        width: 450px;
      }
      
      .form p {
        padding-bottom: 20px;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: .5px;
        color: white;
      }
      
      .container-1 {
        padding-top: 30px;
      }
      
      .main-input {
        font-size: 16px;
        padding: 10px 10px 10px 5px;
        display: block;
        width: 300px;
        border: none;
        border-bottom: 1px solid #6c6c6c;
        background: transparent;
        color: #ffffff;
      }
      
      .main-input:focus {
        outline: none;
        border-bottom-color: #359ad3;
      }
      
      .lebal-email {
        color: #999999;
        font-size: 18px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 5px;
        top: 10px;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
      }
      
      .main-input:focus ~ .lebal-email,
      .main-input:valid ~ .lebal-email {
        top: -20px;
        font-size: 14px;
        color: #359ad3;
      }
      
      .highlight-span {
        position: absolute;
        height: 60%;
        width: 0px;
        top: 25%;
        left: 0;
        pointer-events: none;
        opacity: 0.5;
      }
      
      .main-input:focus ~ .highlight-span {
        -webkit-animation: input-focus 0.3s ease;
        animation: input-focus 0.3s ease;
      }
      
      @keyframes input-focus {
        from {
          background: #359ad3;
        }
      
        to {
          width: 185px;
        }
      }
      
      .submit {
        margin-top: 1.2rem;
        padding: 10px 20px;
        border-radius: 10px;
        background-color: #359ad3;
        margin-top: 40px;
      }
      .forgetpassword{
        display: flex;
        justify-content: center;
        width: 100%;
        a{
        color: #84d9ff;
        text-align: center;
        
        }
        
      }
      a:hover{
        color: #fff;
      }
}