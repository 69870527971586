#gallerymain{


  .title{
        
    font-size: 1.1em;
    font-weight: 900;
    text-align: center;
    margin: 0;
    padding: 0;
    // height: 10vh;
    display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 3vh;
      background-color: #64c1f5;
      // border-radius: 0px 0px 100px 100px;

      h2 {
        color: #ffffff;
        margin: 10px 0px;

    }
  
}

.gallery {
  max-width: 1366px;
  padding: 10px 100px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

.gallery img {
  margin: 20px auto;
  width: 320px;
  aspect-ratio: 4/3;
  box-shadow: 0px 0.5px 5px rgb(145, 145, 145);
  border: solid 10px white;
  border-radius: 10px;
  transition: all 0.5s ease;
}

.gallery img:hover {
  transform: scale(1.1);
  transition: all 0.5s ease;
}
.galleryview{
    min-height: 100vh;
}
#slogan::after, #slogan::before {
  width: 100% !important;
}
}