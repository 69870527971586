.mteamfullscroll{
    .Section{
        height: auto !important;
        .topic {
          
          
        //   height: 20vh;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-bottom: 3vh;
          background-color: #64c1f5;
  
          h2 {
              color: #ffffff;
              margin: 10px 0px;

          }
      }
        
       .data_container{
       margin-top: 40px;
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
        
        .data{
            width: 80vw;
            display: flex;
                justify-content: center;
            img{
                width: 200px;
                // border: 2px solid black;
        // height: 250px;
      
        padding: 7px;
        aspect-ratio: 3.4/4;



            }
            .mteamcollection:nth-child(1){
                width: 100%;
                display: flex;
                justify-content: center;
                

            }
            .mteamcollection:nth-child(2), .mteamcollection:nth-child(3), .mteamcollection:nth-child(4){
            justify-content: space-between;
                width: 33.33%;
            }
            .mteamcollection{
                flex-direction: column;
                .name{
                    font-weight:600;
                    color:#2c2c2c;
                    margin-top: 10px;
                    margin-bottom: 0;
                }
            }
            .mteamcoll, .mteamcollection{
                display: flex;
                justify-content: center;
                align-items: center;
            }
            

        }
       }
    }
    
      
      .title {
        font-size: 1.1em;
        font-weight: 900;
        text-align: center;
        margin: 0;
        padding: 0;
        height: 10vh;
      }
      
     
      
    
      
     
      
      
     
      
    
}

@media only screen and (max-width: 600px) {
    .mteamfullscroll{
        .mteamcollection{
            width: 100% !important;
        }

    }

}