.secondproject{

    .title {
        background-color: #64c1f5;
        // height: 20vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 3vh;
        color: #fff;
    
        h3 {
            color: #000;
        padding: 10px 0px;

        }
        h4{
            font-size: 1.3em !important;
        }
    
        p {
            color: rgb(54, 54, 54);
        }
    }
    
    .description {
        padding: 0 2em;
        // min-height: 50vh;
    }
    
    .imagebox {
        min-height: 50vh;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 1.5em;
        
    
        // align-items: center;
        .full-image {
            display: flex;
            justify-content: center;
            margin: 2vh 0;
            // min-height: 50vh;
            // min-width: 70vw;
            .image{
                min-width: 100%;
            }
    
        }
    }
    
    .tablesubtitle {
        font-style: italic;
        font-weight: 500;
        margin: 0;
    
    }
    
    td {
        width: 24vw;
    }
    
    
        scroll-snap-type: none !important;
        height: auto !important;
        overflow-y: hidden !important;
        .Section:nth-child(1){
            height: auto !important;
            min-height: 100vh;
        }
        button {
            margin: 20px 0;
            position: relative;
            display: inline-block;
            cursor: pointer;
            outline: none;
            border: 0;
            vertical-align: middle;
            text-decoration: none;
            background: transparent;
            padding: 0;
            font-size: inherit;
            font-family: inherit;
           }
           
           button.learn-more {
            width: 15rem;
            height: auto;
           }
           
           button.learn-more .circle {
            transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
            position: relative;
            display: block;
            margin: 0;
            width: 3rem;
            height: 3rem;
            background: #282936;
            border-radius: 1.625rem;
           }
           
           button.learn-more .circle .icon {
            transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            background: #fff;
           }
           
           button.learn-more .circle .icon.arrow {
            transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
            left: 0.625rem;
            width: 1.125rem;
            height: 0.125rem;
            background: none;
           }
           
           button.learn-more .circle .icon.arrow::before {
            position: absolute;
            content: "";
            top: -0.29rem;
            right: 0.0625rem;
            width: 0.625rem;
            height: 0.625rem;
            border-top: 0.125rem solid #fff;
            border-right: 0.125rem solid #fff;
            transform: rotate(45deg);
           }
           
           button.learn-more .button-text {
            transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 0.75rem 0;
            margin: 0 0 0 1.85rem;
            color: #282936;
            font-weight: 700;
            line-height: 1.2;
            text-align: center;
            text-transform: uppercase;
           }
           
           button:hover .circle {
            width: 100%;
           }
           
           button:hover .circle .icon.arrow {
            background: #fff;
            transform: translate(1rem, 0);
           }
           
           button:hover .button-text {
            color: #fff;
           }
           .title {
            font-size: 1.1em;
            font-weight: 900;
            text-align: center;
            margin: 0;
            padding: 0;
            height: 10vh;
          }
    }