#topnav {
    background-color: #fff;
    // position: fixed;
    // z-index: 1;
    // width: 100%;


    .clock {
        background-color: #68b3df;
        margin-bottom: 1px;
        color: #fff;
    }

    .logo-container {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        .nepalflag{
            img{
                height: 60px;
            }
        }
    }

    .navigation::before {
        background-color: #ffca0f;
        height: 3px;
        content: "";
        display: block;
    }
    .clock::before {
        background-color: red;
        height: 3px;
        content: "";
        display: block;
    }

    .navigation {


        .navbar {
            background-color: #359AD3 !important;

            padding: 0;

            .nav-link {
                padding: 0.25em 4em 0.25em 0;


            }



        }

        .dropdown-menu {
            background-color: #359AD3 !important;
        }

        .dropdown-item {
            color: #fff;
        }

        .dropdown-item:hover {
            color: #359AD3;
        }

        .dropdown-item.active {
            background-color: transparent;
            color: #fff;
        }

        .dropdown-item.active:hover {
            background-color: #fff;
            color: #359AD3;
        }


        .dropdown-item:active {
            background-color: transparent;
            color: #fff;
        }
    }

    .navbar-nav {
        a {
            font-size: 1.1em;
        }
    }

    .changing-color {
        animation: myanimation 10s infinite;
    }

    @keyframes myanimation {
        0% {
            background-color: red;
        }

        25% {
            background-color: yellow;
        }

        50% {
            background-color: green;
        }

        75% {
            background-color: brown;
        }

        100% {
            background-color: red;
        }
    }


}

#topnav .navigation .navbar .nav-link {
    color: #fff;
}

#topnav .navigation .navbar .nav-link:hover {
    color: #e2e2e2;
}

@media only screen and (max-width: 600px) {
    .fulllogo{
        img{
            width: 80vw;
        }
    }
    .nepalflag{
        display: none;
    }
}
