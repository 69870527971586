.fullscrollehome {
  scroll-behavior: smooth;
  .Section:nth-child(1){
    height: 133vh !important;
  }


  .Section:nth-child(2) {
    background: #e5f3f9;
    // background-color: #b2eaf5;
    scroll-behavior: smooth;
    position: relative;
    // background-image: url("../../../../Assets//background.jpg");
    background-size:100%;
    background-repeat:no-repeat;
    background-attachment: fixed;
   display: flex;
   align-items: center;
   justify-content: center;
  //  background: rgba(0, 0, 0, 0) url("../../../../Assets//background.jpg") repeat scroll 0 0;




    .text-container {
      opacity: 0.8;
      width: 80vw;
      // background-color: rgba(255, 255, 255, 0.902);
      
      padding: 3%;
      margin-top: 20px;

      text-align: center;

      h1 {
        color: #359AD3;
        font-size: 4em;
        margin: 40px 0;
        -webkit-animation: tracking-in-expand-fwd-top 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
        animation: tracking-in-expand-fwd-top 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
      }
      p{
        color: #000000;
      }
     

      @-webkit-keyframes a {
        0% {
          letter-spacing: -0.5em;
          -webkit-transform: translateZ(-700px) translateY(-500px);
          transform: translateZ(-700px) translateY(-500px);
          opacity: 0;
        }

        40% {
          opacity: 0.6;
        }

        100% {
          -webkit-transform: translateZ(0) translateY(0);
          transform: translateZ(0) translateY(0);
          opacity: 1;
        }
      }

      @keyframes tracking-in-expand-fwd-top {
        0% {
          letter-spacing: -0.5em;
          -webkit-transform: translateZ(-700px) translateY(-500px);
          transform: translateZ(-700px) translateY(-500px);
          opacity: 0;
        }

        40% {
          opacity: 0.6;
        }

        100% {
          -webkit-transform: translateZ(0) translateY(0);
          transform: translateZ(0) translateY(0);
          opacity: 1;
        }
      }

      p {
        margin-bottom: 40px;
        font-size: 18PX;
        text-align: center;
        font-weight: 100;
        line-height: 1.8;
      }
    }
  }
  // .Section:nth-child(2)::before{
  //   // background:rgba(0,0,0,0) url("../../../../Assets//background.jpg");
  //   background-color: #000000;
  //   background-size: cover;
  //   background-repeat: no-repeat;
  //   background-attachment: fixed;
  //   position: absolute;
  //   top: 0px;
  //   right: 0px;
  //   bottom: 0px;
  //   left: 0px;
  //   opacity: 0.7;
  //   content: "";

  // }

  .Section:nth-child(3) {
    background-image: linear-gradient(#5cbcf4, #fff);
  }

  .Section:nth-child(4) {
    .map {
      width: 100%;

      .mapdata {
        width: 100%;
        height: 80vh;
      }
    }
  }
  .button {
    --color: #359ad3;
    padding: 0.8em 1.7em;
    background-color: transparent;
    border-radius: .3em;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: .5s;
    font-weight: 400;
    font-size: 12px;
    border: 1px solid;
    font-family: inherit;
    text-transform: uppercase;
    color: var(--color);
    z-index: 1;
    // margin-top: 40px;
   }
   
   .button::before, .button::after {
    content: '';
    display: block;
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    background-color: var(--color);
    transition: 1s ease;
   }
   
   .button::before {
    top: -1em;
    left: -1em;
   }
   
   .button::after {
    left: calc(100% + 1em);
    top: calc(100% + 1em);
   }
   
   .button:hover::before, .button:hover::after {
    height: 410px;
    width: 410px;
   }
   
   .button:hover {
    color: rgb(255, 255, 255);
   }
   
   .button:active {
    filter: brightness(.8);
   }
}
.button-new {
  display: inline-block;
  padding: 12px 24px;
  border: 1px solid #4f4f4f;
  border-radius: 4px;
  transition: all 0.2s ease-in;
  position: absolute;
  overflow: hidden;
  font-size: 19px;
  color: black;
  z-index: 1;
  right: 0;
  margin-top: 50px;
 }
 
 .button-new:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scaleY(1) scaleX(1.25);
  top: 100%;
  width: 140%;
  height: 180%;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
 }
 
 .button-new:after {
  content: "";
  position: absolute;
  left: 55%;
  transform: translateX(-50%) scaleY(1) scaleX(1.45);
  top: 180%;
  width: 160%;
  height: 190%;
  background-color: #39bda7;
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
 }
 
 .button-new:hover {
  color: #ffffff;
  border: 1px solid #39bda7;
 }
 
 .button-new:hover:before {
  top: -35%;
  background-color: #39bda7;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
 }
 
 .button-new:hover:after {
  top: -45%;
  background-color: #39bda7;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
 }
 .homereport{
  position: relative;
  height: 100%;
  .reports{
    cursor: pointer;
  }
  .items,.griditem{
    box-sizing: border-box;
  }
 
    .items:hover{
  transition: padding 0.3s ease-out;



      padding: 12px;
      h5{
  font-size: 20px;
  text-decoration: underline;
  text-decoration-style: dotted;
  transition: font-size 0.3s ease-out;
  color: #4474f7;
      }
    }
  
h1{
  margin-bottom: 50px;
  text-align: center;

}



 }
 .closebutton{
  text-align: right;
  .closeicon{
  font-size: xx-large;
  cursor: pointer;
  
  

  }
}
.css-yrhldv::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}



 @media only screen and (max-width: 600px) {
  .fullscroll{
    height: 100vh !important;
    .Section{
      height: 90vh !important;
    }
   
    .Section:nth-child(1){
      height: auto !important;
    }
    .carouselimage {
      aspect-ratio: 16/9;
      height: auto;
      width: 100%;
  }
  .caousel-container {
    min-height: auto !important;
    height: min-content;
}
.MuiImageList-root{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  img{
    width: 70vw;
    aspect-ratio: 16/9;
    margin: 10px 0;
    overflow: hidden;
  }
}
.css-1u78c8g-MuiImageList-root {
  width: auto !important;
  height: auto !important;
}
h1{
  margin: 0 0 40px 0 !important;
  padding: 0 !important;
}
.footerfullscreeen{
  height: auto !important;
}
.Section:nth-child(4){
  height: auto !important;
}
.text-container{
  p{
  text-align: justify !important;
  }
}

  }
  .css-yrhldv{
    width: 90%;
  }
 
  
}