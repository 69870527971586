.bodfullscroll{
    .Section{
        height: auto !important;
        .topic {
          
          
          // height: 20vh;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-bottom: 3vh;
          background-color: #64c1f5;
  
          h2 {
              color: #ffffff;
              margin: 10px 0px;
          }
      }
        
       .data_container{
       margin-top: 40px;
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
        
        .data{
            width: 80vw;
            display: flex;
                justify-content: center;
            img{
                width: 200px;
                // border: 2px solid black;
        // height: 250px;
        border-radius: 1rem;
        padding: 20px;
        aspect-ratio: 3.4/4;



            }
            .bodcollection:nth-child(1){
                width: 100%;
                display: flex;
                justify-content: center;
                

            }
            .bodcoll, .bodcollection{
                display: flex;
                justify-content: center;
                align-items: center;
            }
            

        }
       }
    }
    .flip-card {
        background-color: transparent;
        width: 250px;
        height: 320px;
        perspective: 1000px;
        font-family: sans-serif;
      }
      
      .title {
        // font-size: 1.1em;
        font-weight: 600;
        text-align: center;
        margin: 0;
        padding: 0;
        // height: 10vh;
      }
      
      .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        // text-align: center;
        transition: transform 0.8s;
        transform-style: preserve-3d;
      }
      
      .flip-card:hover .flip-card-inner {
        transform: rotateY(180deg);
      }
      
      .flip-card-front, .flip-card-back {
        // box-shadow: 0 8px 14px 0 rgba(0,0,0,0.2);
        position: absolute;
        // display: flex;
        flex-direction: column;
        // justify-content: center;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        // border: 1px solid #359ad3;
        border-radius: 1rem;
      }
      .flip-card-back{
        height: auto !important;
        min-height: 100%;
        padding: 10px;
      }
      
      .flip-card-front {
        // background: linear-gradient(120deg, bisque 60%, rgb(255, 231, 222) 88%,
        //    rgb(255, 211, 195) 40%, rgba(255, 127, 80, 0.603) 48%);
        // color: coral;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .flip-card-back {
        // background: linear-gradient(120deg, rgb(255, 174, 145) 30%, coral 88%,
        //    bisque 40%, rgb(255, 185, 160) 78%);
        color: rgb(0, 0, 0);
        transform: rotateY(180deg);
      }
}