
.center {
  width: 80%;
  margin: auto;
}

.dropdown-item.active:hover {
  color: #359AD3;
  background-color: #fff;
}

.dropdown-item.active {
  color: #fff;
  background-color: #359AD3;
}

.dropdown-item {
  color: #fff;
}

.fullscroll{
  position: relative;
  width: 100%;
  height: 100vh;
  scroll-behavior: smooth;
  // overflow-y: scroll;
  scroll-snap-type: y mandatory;
  

  .Section {
      width: 100%;
      height: 100vh;
      scroll-snap-align: start;
      // overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center ;
  }
  .Section:nth-child(1) {
    display: block;
}
}
.footerfullscreeen{
  height: auto !important;
  overflow: hidden;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  /* left: 200px; */
  z-index: 1030;
}
.adminnavbar {
  position: fixed !important;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}
th,td{
  font-size: 1em !important;
}
th{
  font-weight: bold;
}

.menubuttonlink{
  span{
    font-size:medium;
  }
  
}
.MuiTable-root {
  tr, td{
    font-size: medium;
  }
}