.carouselimage{
    aspect-ratio: 2 / 1;
    height: 100vh;
    width: 100%;
    -webkit-animation: kenburns-top 10s ease-out both;
	        animation: kenburns-top 10s ease-out both;
}
.caousel-container{
        // padding: 10px 10px;
        min-height: 80vh;
        height:min-content;

       
}
        

 @-webkit-keyframes carouselimage {
    0% {
      -webkit-transform: scale(1) translateY(0);
              transform: scale(1) translateY(0);
      -webkit-transform-origin: 50% 16%;
              transform-origin: 50% 16%;
    }
    100% {
      -webkit-transform: scale(1.25) translateY(-15px);
              transform: scale(1.25) translateY(-15px);
      -webkit-transform-origin: top;
              transform-origin: top;
    }
  }
  @keyframes kenburns-top {
    0% {
      -webkit-transform: scale(1) translateY(0);
              transform: scale(1) translateY(0);
      -webkit-transform-origin: 50% 16%;
              transform-origin: 50% 16%;
    }
    100% {
      -webkit-transform: scale(1.25) translateY(-15px);
              transform: scale(1.25) translateY(-15px);
      -webkit-transform-origin: top;
              transform-origin: top;
    }
  }
  