.fullscrollabout {
    .Section:nth-child(1) {
    min-height: 100vh;

        .aboutustext {
            width: 100%;
            height: 70%;
            display: flex;
            justify-content: center;
            align-items: center;

            h1,
            p {
                width: 80vw;
                text-align: justify;
            }

            P {
                font-size: 15PX;
            }
        }
    }
    

    .message:nth-child(even) {
        background-color: #f9f9f9;

        div:nth-child(1) {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80vw;

            .mfimage {
                // width: 50%;
                height: 80vh;
                overflow: hidden;
                

            }
        }

        

        .mftext {
            display: flex;
            // justify-content: center;
            // align-items: center;
            padding: 40px;
            background-color: #FFF;
            flex-direction: column;
            h5{
                font-size:larger;
                font-weight: 700;
            }

        }

    }

    .message:nth-child(odd) {
        div:nth-child(1) {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80vw;
            flex-direction: row-reverse;

            .mfimage,
            .mftext {
                // width: 50%;
                height: 80vh;

            }
        }

        .mfimage {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .mftext {
            display: flex;
            justify-content: center;
            padding: 40px;
            background-color: #f9f9f9;
            flex-direction: column;
            text-align: right;
            h5{
                font-size:larger;
                font-weight: 700;
            }

        }
    }

    .mfimage {
        // background-color: red;
        flex-direction: column !important;
        margin: 3vw;

        img {
            // background-color: red;
            width: 35%;
            width: 120px;
            height: 150px;
            border: 4px solid rgb(204, 204, 204);
            border-radius: 10px;
            

        }
        
    }

    .mftext {
        p {
            font-size: 15PX;
            text-align: justify;

        }
        
    }

    @media only screen and (max-width: 600px) {
        
        .message {
            height: auto !important;

            div {
                display: flex;
                flex-direction: column !important;
                width: 100vw !important;

                .mfimage {
                    height: auto !important;
                    margin: 10px 0;
            


                    img {
                        // width: 50%;

                    }
                }

                .mftext {
                    height: auto !important;
                    text-align: left !important;
          

                }
            }
        }

        // .mfimage::before {

        //     height: 2px;
        //     width: 100%;
        //     content: "";
        //     background-color: #b1b1b1;
        //     margin: 40px 0;

        // }
    }

    
}