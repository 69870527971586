.fullscrollorganizationchart{
    .topic {
        display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-bottom: 3vh;
          background-color: #64c1f5;
  
          h2 {
              color: #ffffff;
              margin: 10px 0px;

          }
    }}
    .title {
        font-size: 1.1em;
        font-weight: 900;
        text-align: center;
        margin: 0;
        padding: 0;
        height: 10vh;
      }



@media only screen and (max-width: 600px) {
    .fullscrollorganizationchart{
        .topic {
          
          
            // height: 20vh;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-bottom: 3vh;
            background-color: #64c1f5;
    
            h2 {
                color: #ffffff;
            }
        }

        .mtimage{
            aspect-ratio: 16/9;
            width: 100vw;
        }
    }
}
