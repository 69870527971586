.reportscontainer{
    min-height: 80vh;
    .title{
        
        font-size: 1.1em;
        font-weight: 900;
        text-align: center;
        margin: 0;
        padding: 0;
        // height: 10vh;
        display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-bottom: 3vh;
          background-color: #64c1f5;

          h3 {
            color: #ffffff;
            margin: 10px 0px;

        }
      
    }
    .button {
        --color: #359ad3;
        padding: 0.8em 1.7em;
        background-color: transparent;
        border-radius: .3em;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        transition: .5s;
        font-weight: 400;
        font-size: 12px;
        border: 1px solid;
        font-family: inherit;
        text-transform: uppercase;
        color: var(--color);
        z-index: 1;
       }
       
       .button::before, .button::after {
        content: '';
        display: block;
        width: 50px;
        height: 50px;
        transform: translate(-50%, -50%);
        position: absolute;
        border-radius: 50%;
        z-index: -1;
        background-color: var(--color);
        transition: 1s ease;
       }
       
       .button::before {
        top: -1em;
        left: -1em;
       }
       
       .button::after {
        left: calc(100% + 1em);
        top: calc(100% + 1em);
       }
       
       .button:hover::before, .button:hover::after {
        height: 410px;
        width: 410px;
       }
       
       .button:hover {
        color: rgb(255, 255, 255);
       }
       
       .button:active {
        filter: brightness(.8);
       }
       
}