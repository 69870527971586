.visionmission{
    margin-top: 5vh;
    div{
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h1,p,ul{
            text-align: left;
            width: 80vw;
            font-size: 15px;
        }
    }
    h1{
        font-size: 20px !important;
        font-weight: bold;
    }
}